@import "./inputs";
@import "./colors";
@import "./typography";
@import "./mixins";

// Dimensions
$appPadding: 8vw;

// Scroll bar
$scrollbar: 6px;

// Borders & its radius
$radius: 4px;
$radius8: 8px;
$radius12: 12px;
$radiusFull: 1000px;
$inputBorder: 2px solid $borderColor;
$border: 1px solid $borderColor;

// Animations
$transition: 200ms ease-in;
$fastTransition: 140ms ease-out;

// Opacity
$opacity: 0.84;

// Shadows
$buttonShadowHover: 0px 8px 24px 8px rgb(0 0 0 / 12%), 0px 0px 64px rgb(0 0 0 / 0%);
$buttonShadowActive: 0px 8px 24px 8px rgb(0 0 0 / 8%), 0px 0px 64px rgb(0 0 0 / 0%);
// $shadow: 0px 8px 24px 8px rgb(120 120 120 / 8%), 0px 0px 64px rgb(0 0 0 / 0%);
$shadow: 0 3px 20px rgb(0 0 0 / 9%);

// Separators
$SectionSeparatorWeb: 200px;
$SectionSeparatorWeb2: 100px;
$SectionSeparatorWeb3: 60px;
$SectionSeparatorMobile: 100px;
$SectionSeparatorMobile2: 60px;
$SectionSeparatorMobile3: 40px;