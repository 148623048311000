//Grey scale
$black: #2E3234;
$grey: #92919d;//#7e868d
$white: #fff;

//Branding
// $color-primary: #ff3816;
$color-primary: #2E3234; //#ec4847;
$color-secondary: #7C7D81;
// $color-accent: lighten(#2E3234, 8%);
$color-light: #f4f6fa;
$color-title: $black;//lighten(#39374d, 12%);
$color-detail: $black;//lighten(#39374d, 40%);
$textColor: $black;

//Others
$borderColor3: #ccc;
$borderColor2: #ddd;
$borderColor: #e8e8e8;
