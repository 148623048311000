body {
    margin: 0;
    padding: 0;
}

* {
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button,
input,
textarea {
  background: none;
  border: none;
  outline: none;
  padding: 0;
}

button,
input[type="button"],
input[type="submit"]{
    cursor: pointer;
}

button::selection,
input[type="button"]::selection,
input[type="submit"]::selection {
  background: transparent;
}