// CONSIDERATIONS
/* All the dimension values must be multiple of 4 */

//Variables
@import "./variables";

// Elements
@import "./inputs";

// Basic resets
@import "./resetDefault";

//DEFINITIONS

* {
  // box-sizing: unset;
  box-sizing: border-box;
}

body {
  color: $textColor;
  font: $text;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  // background: #f4f7f8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9b9aa5;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #39374d;
}

h1 {
  // font: $heading;
  font: $title1;
  margin-bottom: 76px;
}

h2 {
  // font: $subHeading;
  font: $title2;
  margin-bottom: 12px;
}

h3 {
  font: $title3;
  margin-bottom: 8px;
}

h1,
h2,
h3 {
  text-align: center;
}

p {
  color: $textColor;
  font: $text;
  line-height: 32px !important;
  margin-bottom: 12px;
}

a {
  color: $color-primary;
  font: $link;
  text-decoration: underline;
}

label {
  // font: $label;
  // color: $black;
  margin-bottom: 8px;
  display: block;
  font: $biggerText;
  color: $grey;
}

input,
select,
textarea {
  font-family: "M";
  border: $inputBorder;
  height: 44px;
  padding: 0 12px;
}

textarea {
  padding: 24px;
  height: unset;
  height: 104px;
}

input::placeholder,
textarea::placeholder {
  // font: $littleText;
  color: lighten($black, 40%);
}

/* FONTS & TEXT */
// .font12 {

// }
.regular {
  font-family: "R";
}
.bold {
  font-family: "M" !important;
}
.extraBold {
  font-family: "B" !important;
}
.txtLeft {
  text-align: left;
}
.txtRight {
  text-align: right;
}
.txtCenter {
  text-align: center;
}
.txtCenterAll * {
  text-align: center !important;
}
.txtAlgnJustify {
  text-align: justify;
}
.txtAlgnDefault {
  text-align: inherit;
}
.text {
  font: $text;
}
.biggerText {
  font: $biggerText;
}
.biggestText {
  font: $biggestText;
}
.littleText {
  font: $littleText;
}
.heading {
  font: $heading;
}
.subHeading {
  font: $subHeading;
}
.title1 {
  font: $title1;
}
.title2 {
  font: $title2;
}
.title3 {
  font: $title3;
}
.heroText {
  font: $heroText;
  text-align: left !important;
}
.txtDecoNone {
  text-decoration: none !important;
}
.italic {
  font-style: italic;
}

@media screen and (max-width: 660px) {
  .title1 {
    // font-size: 32px;
  }
  .title2 {
    font-size: 24px;
  }
  .title3 {
    font-size: 20px;
  }
  .heroText {
    font-size: 32px;
  }
}



/* DISPOSITION */
.flex {
  display: flex;
}
.flexWrap {
  display: flex;
  flex-wrap: wrap;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vTop {
  display: flex;
  align-items: flex-start;
}
.vCenter {
  display: flex;
  align-items: center;
}
.hCenter {
  display: flex;
  justify-content: center;
}
.column {
  display: flex;
  flex-direction: column;
}
.columnReverse {
  display: flex;
  flex-direction: column-reverse;
}
.rowReverse {
  display: flex;
  flex-direction: row-reverse;
}
.row {
  display: flex;
  flex-direction: row;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
}
.spaceAround {
  display: flex;
  justify-content: space-around;
}
.spaceEvenly {
  display: flex;
  justify-content: space-evenly;
}
.flexEnd {
  display: flex;
  justify-content: flex-end;
}
.placeSelfEnd {
  place-self: flex-end;
}
.right {
  justify-content: right;
}

.block {
  display: block;
}

.hide {
  display: none;
}

.box {
  display: -moz-box;
  display: -webkit-box;
}

.inlineBlock {
  display: inline-block;
}

.floatR {
  float: right;
}
.floatL {
  float: left;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}

.borderBox {
  box-sizing: border-box;
}

.translateCenter {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

@for $i from 0 to 101 {
  .gap#{$i} {
    gap: #{$i}px;
    column-gap: #{$i}px;
  }

  .gap#{$i}vw {
    gap: #{$i}vw;
    column-gap: #{$i}px;
  }

  @if $i < 100 {
    .gap#{$i}pct {
      gap: #{$i}#{"%"};
      column-gap: #{$i}#{"%"};
    }
  }
}

// Rotation
@for $i from 0 to 361 {
  @if $i % 45 == 0 {
    .rotate#{$i} {
      transform: rotate(#{$i}deg);
    }
  }
}

// Distance from viewport border in px
.top0 {
  top: 0 !important;
}
.right0 {
  right: 0 !important;
}
.bottom0 {
  bottom: 0 !important;
}
.left0 {
  left: 0 !important;
}
@for $i from 0 to 200 {
  @if $i % 4 == 0 {
    .top#{$i} {
      top: #{$i}px;
    }
    .right#{$i} {
      right: #{$i}px;
    }
    .bottom#{$i} {
      bottom: #{$i}px;
    }
    .left#{$i} {
      left: #{$i}px;
    }

    .top#{$i}vh {
      top: #{$i}vh;
    }
    .right#{$i}vw {
      right: #{$i}vw;
    }
    .bottom#{$i}vh {
      bottom: #{$i}vh;
    }
    .left#{$i}vw {
      left: #{$i}vw;
    }
  }
}

/* Various */
.noSelect,
.noSelectAll * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.capitalize {
  text-transform: capitalize;
}

.upper {
  text-transform: uppercase;
}

.lower {
  text-transform: lowercase;
}

.scaled08 {
  transform: scale(0.8);
}

/* IMG Background */
.noRepeat {
  background-repeat: no-repeat;
}
.cover {
  background-size: cover;
}
.contain {
  background-size: contain;
}
.imgCenter{
  background-position: center center;
}

/* OVERFLOWS */
.overflowAuto {
  overflow: auto;
}
.overflowXAuto {
  overflow-x: auto;
}
.overflowYAuto {
  overflow-y: auto;
}
.scroll {
  overflow: scroll;
}
.scrollX {
  overflow-x: scroll;
}
.scrollY {
  overflow-y: scroll;
}
.hidden {
  overflow: hidden;
}
.hiddenX {
  overflow-x: hidden;
}
.hiddenY {
  overflow-y: hidden;
}

/* CURSOR */
.cursorPointer {
  cursor: pointer;
}
.cursorMove {
  cursor: move;
}
.cursorDefault {
  cursor: default;
}
.cursorMoveOnClick:active {
  cursor: move;
}

/* OPACITY */
//
@for $i from 0 to 100 {
  @if $i % 10 == 0 {
    .opacity#{$i} {
      opacity: #{$i} / 100;
    }
  }
}

/* COLORS */
.bgPrimary {
  background: $color-primary;
}
.bgAllPrimary * {
  background: $color-primary;
}
.fcPrimary {
  color: $color-primary;
}
.bgSecondary {
  background: $color-secondary;
}
.bgAllSecondary * {
  background: $color-secondary;
}
.fcSecondary {
  color: $color-secondary;
}
// .bgAccent {
//   background: $color-accent;
// }
// .fcAccent {
//   color: $color-accent;
// }
.bgLightSecondary {
  background: lighten($color-secondary, 40%);
}
.fcLightSecondary {
  color: lighten($color-secondary, 80%);
}
.fcParagraph {
  color: $textColor;
}
.bglight {
  background: $color-light;
}
.fcBlack {
  color: $black;
}
.fcAllBlack * {
  color: $black !important;
}
.bgBlack {
  background-color: $black;
}
.fcTextColor {
  color: $textColor;
}
.fcWhite {
  color: white !important;
}
.fcAllWhite * {
  color: white !important;
}
.bgWhite {
  background-color: white !important;
}
.fcAllPrimary * {
  color: $color-primary;
  fill: $color-primary;
}
.fcAllSecondary * {
  color: $color-secondary;
  fill: $color-secondary;
}
.fillAllBlack * {
  fill: $black !important;
}
.fillAllWhite * {
  fill: white !important;
}

/* SHADOWS */
.shadowNone {
  box-shadow: none !important;
}
.shadow {
  box-shadow: $shadow;
}
.hoverShadow:hover {
  transition: $transition;
  box-shadow: $shadow;
}

/*   MARGINS   */
.mAuto {
  margin: auto;
}
.mrAuto {
  margin-right: auto;
}
.mlAuto {
  margin-left: auto;
}
.mbAuto {
  margin-bottom: auto;
}
.mtAuto {
  margin-top: auto;
}

.m0 {
  margin: 0 !important;
}
.my0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.mx0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mt0 {
  margin-top: 0 !important;
}
.mr0 {
  margin-right: 0 !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.ml0 {
  margin-left: 0 !important;
}

@for $i from 0 to 400 {
  @if $i % 4 == 0 or $i == 0 or $i == 2 {
    .m#{$i} {
      margin: #{$i}px;
    }
    .my#{$i} {
      margin-top: #{$i}px;
      margin-bottom: #{$i}px;
    }
    .mx#{$i} {
      margin-right: #{$i}px;
      margin-left: #{$i}px;
    }
    .mt#{$i} {
      margin-top: #{$i}px;
    }
    .mr#{$i} {
      margin-right: #{$i}px;
    }
    .mb#{$i} {
      margin-bottom: #{$i}px;
    }
    .ml#{$i} {
      margin-left: #{$i}px;
    }

    // %
    .m#{$i}pct {
      margin: #{$i}#{"%"};
    }
    .my#{$i}pct {
      margin-top: #{$i}#{"%"};
      margin-bottom: #{$i}#{"%"};
    }
    .mx#{$i}pct {
      margin-right: #{$i}#{"%"};
      margin-left: #{$i}#{"%"};
    }
    .mt#{$i}pct {
      margin-top: #{$i}#{"%"};
    }
    .mr#{$i}pct {
      margin-right: #{$i}#{"%"};
    }
    .mb#{$i}pct {
      margin-bottom: #{$i}#{"%"};
    }
    .ml#{$i}pct {
      margin-left: #{$i}#{"%"};
    }

    // by Viewport height
    .m#{$i}vh {
      margin: #{$i}vh;
    }
    .my#{$i}vh {
      margin-top: #{$i}vh;
      margin-bottom: #{$i}vh;
    }
    .mx#{$i}vh {
      margin-right: #{$i}vh;
      margin-left: #{$i}vh;
    }
    .mt#{$i}vh {
      margin-top: #{$i}vh;
    }
    .mr#{$i}vh {
      margin-right: #{$i}vh;
    }
    .mb#{$i}vh {
      margin-bottom: #{$i}vh;
    }
    .ml#{$i}vh {
      margin-left: #{$i}vh;
    }

    // by Viewport width
    .m#{$i}vw {
      margin: #{$i}vw;
    }
    .my#{$i}vw {
      margin-top: #{$i}vw;
      margin-bottom: #{$i}vw;
    }
    .mx#{$i}vw {
      margin-right: #{$i}vw;
      margin-left: #{$i}vw;
    }
    .mt#{$i}vw {
      margin-top: #{$i}vw;
    }
    .mr#{$i}vw {
      margin-right: #{$i}vw;
    }
    .mb#{$i}vw {
      margin-bottom: #{$i}vw;
    }
    .ml#{$i}vw {
      margin-left: #{$i}vw;
    }
  }
}

/*   PADDINGS   */
.p0 {
  padding: 0 !important;
}

@for $i from 0 to 200 {
  @if $i % 4 == 0 or $i == 0 or $i == 2 {
    .p#{$i} {
      padding: #{$i}px;
    }
    .py#{$i}x0 {
      padding: #{$i}px 0;
    }
    .py0x#{$i} {
      padding: 0 #{$i}px;
    }
    .py#{$i} {
      padding-top: #{$i}px;
      padding-bottom: #{$i}px;
    }
    .px#{$i} {
      padding-right: #{$i}px;
      padding-left: #{$i}px;
    }
    .pt#{$i} {
      padding-top: #{$i}px;
    }
    .pr#{$i} {
      padding-right: #{$i}px;
    }
    .pb#{$i} {
      padding-bottom: #{$i}px;
    }
    .pl#{$i} {
      padding-left: #{$i}px;
    }

    // Viewport height
    .p#{$i}vh {
      padding: #{$i}vh;
    }
    .py#{$i}x0vh {
      padding: #{$i}vh 0;
    }
    .py0x#{$i}vh {
      padding: 0 #{$i}vh;
    }
    .pt#{$i}vh {
      padding-top: #{$i}vh;
    }
    .pr#{$i}vh {
      padding-right: #{$i}vh;
    }
    .pb#{$i}vh {
      padding-bottom: #{$i}vh;
    }
    .pl#{$i}vh {
      padding-left: #{$i}vh;
    }
    .px#{$i}vh {
      padding-left: #{$i}vh;
      padding-right: #{$i}vh;
    }
    .py#{$i}vh {
      padding-top: #{$i}vh;
      padding-bottom: #{$i}vh;
    }

    // Viewport width
    .p#{$i}vw {
      padding: #{$i}vw;
    }
    .py#{$i}x0vw {
      padding: #{$i}vw 0;
    }
    .py0x#{$i}vw {
      padding: 0 #{$i}vw;
    }
    .pt#{$i}vw {
      padding-top: #{$i}vw;
    }
    .pr#{$i}vw {
      padding-right: #{$i}vw;
    }
    .pb#{$i}vw {
      padding-bottom: #{$i}vw;
    }
    .pl#{$i}vw {
      padding-left: #{$i}vw;
    }
    .px#{$i}vw {
      padding-left: #{$i}vw;
      padding-right: #{$i}vw;
    }
    .py#{$i}vw {
      padding-top: #{$i}vw;
      padding-bottom: #{$i}vw;
    }
  }
}

/* DIMENSIONS */
.fullH {
  height: 100%;
}
.fullW {
  width: 100%;
}
.hAuto {
  height: auto;
}
.wAuto {
  width: auto;
}
.fitContent {
  width: fit-content;
  height: fit-content;
}
.fitContentW {
  width: fit-content;
}
.fitContentH {
  height: fit-content;
}
.availableH {
  height: -webkit-fill-available;
  height: fill-available;
  height: -moz-available;
}
.availableW {
  width: -webkit-fill-available;
  width: fill-available;
  width: -moz-available;
}

.wAppPadding {
  width: $appPadding;
}

@for $i from 4 to 772 {
  @if $i % 4 == 0 or $i == 50 {
    .h#{$i} {
      height: #{$i}px;
    }
    .w#{$i} {
      width: #{$i}px;
    }
    .minH#{$i} {
      min-height: #{$i}px;
    }
    .minW#{$i} {
      min-width: #{$i}px;
    }
    .maxH#{$i} {
      max-height: #{$i}px;
    }
    .maxW#{$i} {
      max-width: #{$i}px;
    }

    // by Viewport height
    .h#{$i}vh {
      height: #{$i}vh;
    }
    .minH#{$i}vh {
      min-height: #{$i}vh;
    }
    .maxH#{$i}vh {
      max-height: #{$i}vh;
    }

    // by Viewport width
    //If 100, remains 94 substracting scrollbar width.
    // @if $i == 100{
    //   .w#{$i}vw {
    //     width: calc(#{$i}vw - #{$scrollbar});
    //   }
    //   .minW#{$i}vw {
    //     min-width: calc(#{$i}vw - #{$scrollbar});
    //   }
    //   .maxW#{$i}vw {
    //     max-width: calc(#{$i}vw - #{$scrollbar});
    //   }
    // } @else {
    .w#{$i}vw {
      width: #{$i}vw;
    }
    .minW#{$i}vw {
      min-width: #{$i}vw;
    }
    .maxW#{$i}vw {
      max-width: #{$i}vw;
    }
    // }

    // by %
    .h#{$i}pct {
      height: #{$i}#{"%"};
    }
    .w#{$i}pct {
      width: #{$i}#{"%"};
    }
    .minH#{$i}pct {
      min-height: #{$i}#{"%"};
    }
    .minW#{$i}pct {
      min-width: #{$i}#{"%"};
    }
    .maxH#{$i}pct {
      max-height: #{$i}#{"%"};
    }
    .maxW#{$i}pct {
      max-width: #{$i}#{"%"};
    }
  }
}

/* BORDERS */
.border {
  border: $border !important;
}
.borderT {
  border-top: $border !important;
}
.borderR {
  border-right: $border !important;
}
.borderB {
  border-bottom: $border !important;
}
.borderL {
  border-left: $border !important;
}
.bcPrimary {
  border-color: $color-primary !important;
}
.border2 {
  border-width: 2px !important;
}
.border3 {
  border-width: 3px !important;
}
.border4 {
  border-width: 4px !important;
}
.border8 {
  border-width: 8px !important;
}
//Radius
.radius0 {
  border-radius: 0 !important;
}
@for $i from 2 to 13 {
  @if $i % 4 == 0 or $i == 2 {
    .radius#{$i} {
      border-radius: #{$i}px;
    }
    .radius0#{$i}#{$i}0 {
      border-radius: 0 #{$i}px #{$i}px 0;
    }
    .radius#{$i}00#{$i} {
      border-radius: #{$i}px 0 0 #{$i}px;
    }
  }
}
.radius1000001000 {
  border-radius: 1000px 0 0 1000px;
}
.radius0100010000 {
  border-radius: 0 1000px 1000px 0;
}
.borderNone {
  border: none !important;
}
.borderTNone {
  border-top: none !important;
}
.borderRNone {
  border-right: none !important;
}
.borderBNone {
  border-bottom: none !important;
}
.borderLNone {
  border-left: none !important;
}
.fullRadius {
  border-radius: 100% !important;
}

/* CUSTOM CLASSES */
.AppPadding {
  padding-left: $appPadding;
  padding-right: $appPadding;
}
.AppLeftPadding {
  padding-left: $appPadding;
}
.AppRightPadding {
  padding-right: $appPadding;
}

/* Animation */
.transition * {
  transition: $transition;
}

/* OBJECTS ------------------------------------------- */

/* BUTTONS & INPUTS */

.btnPrimary {
  background: $color-primary;
  color: #fff !important;
  padding: 0 28px;
  letter-spacing: 0.6px;
  font: $button;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  line-height: 52px;
  transition: $fastTransition;
  text-decoration: none;

  &:hover {
    box-shadow: $buttonShadowHover;
    // background-color: $color-accent;
  }

  &:active {
    background: darken($color-primary, 8%);
    box-shadow: $buttonShadowActive;
    // opacity: .88;
  }
}

.MicrofactoriaLogo {
  font: $logo !important;
}

.btnSecondary {
  background: #fff;
  color: $color-primary !important;
  border: $border;
  padding: 0 28px;
  border-radius: $radius8;
  font: $button;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  line-height: 52px;
  transition: $fastTransition;

  &:hover {
    box-shadow: $buttonShadowHover;
  }

  &:active {
    box-shadow: $buttonShadowActive;
  }

  & svg * {
    fill: $color-primary !important;
  }
}

.BtnLink {
  text-decoration: underline;
}

.InputNButton .btnPrimary {
  background: $color-primary;
  color: $white;
  height: $inputLineHeight;
  padding: 0 28px;
  border-radius: 0 $radius $radius 0;
  font: $button;
}
.InputNButton .btnPrimary:active {
  background: darken($color-primary, 5%);
}

.InfoNum {
  // height: 286px;
  width: 220px;
  text-align: center;
  margin-bottom: 132px;
  & h1 {
    font-size: 84px;
    color: #707070;
    margin: 0;
    & span {
      font-size: 28px;
    }
  }
  & h2 {
    // font-size: 32px;
    color: $color-primary;
    font-family: "M";
    margin-top: 20px;
    text-align: center;
  }
  & p {
    font-size: 18px;
  }
}

/* ICONS */
.Icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms ease;
}
.Icon.fcAccent:hover {
  color: $color-primary;
  border-bottom: 2px solid $color-primary;
  padding-bottom: 12px;
}
.MiniIcon {
}

.Languages {
  cursor: pointer;

  &:hover .hide {
    display: block;
    transition: 200ms ease;
  }
  & button:active {
    color: $color-primary;
    transition: 200ms ease;
  }
}

.Logo {
  & * {
    font-family: "M";
  }

  & a {
    display: flex;
    text-decoration: none;
    // color: $black;
  }
  & svg {
    height: 28px;
    width: auto;
  }
}

// Hamburger/Menu button for mobile
.BtnMenu {
  // height: 40px;
  // width: 40px;
  // margin: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: $color-primary;
  transition: $transition;
  border-radius: 4px;

  // &:hover {
  //   // border: 1px solid $color-primary;
  // }

  // &:active {
  //   // color: $color-accent;
  //   // opacity: $opacity;
  // }
}

.Menu {
  display: flex;
  // background-color: $white;
  transition: $transition;
  align-items: center;

  & li {
    margin-right: 48px;
  }

  & li a,
  & li a svg {
    color: $grey;
    text-decoration: none;
  }

  & li a:hover {
    color: $color-primary;
  }

  & li a.active {
    // color: $black;
    // color: $white;
    // font-weight: bold;
  }

  & li a:hover svg {
    color: $color-detail;
  }

  & li a svg * {
    // color: $grey !important;
    // fill: $grey;
  }

  & li:last-child {
    margin: 0;
  }
}

.MobileMenu {
  background: $white;
  position: absolute;
  left: 0;
  top: 72px;
  width: 100%;
  padding-bottom: 24px;

  & li {
    padding: 24px 8px;
    text-align: center;
  }

  & button {
    margin: 0 auto;
  }
}

// For UL's used in real text-related lists
.TextList {
  list-style: inside;
  font: $text;
  color: $black;
  padding: 0 0 20px 20px;
  font-weight: bold;
  & li {
    margin: 0 0 20px;
  }
}

.FooterColumn {
  max-width: 240px;
  margin-top: 40px;
  padding: 0 40px 0 0;

  & h2 {
    font-size: 20px;
    margin-bottom: 16px;
    text-align: left;
  }

  & li {
    font-size: 13px;
    margin-bottom: 12px;

    & p {
      margin: 0;
      line-height: initial;
    }
  }

  & svg {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.2rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    margin: 8px 16px 0 0;
  }
}

/* TOOLTIP */

[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  // box-shadow: $shadow;
  background: $color-primary;
  position: absolute;
  color: #fff;
  padding: 4px 8px;
  border-radius: $radius;
  // font: $text;
  transform: translate(-80%, 80%);
  width: max-content;
}

/* TOOLTIP */

.AccessForm {
  overflow-y: auto;
  box-sizing: border-box;
  margin: 0 auto;
  border: $border;
  border-radius: $radius8;
  // box-shadow: $shadow;
  background: $white;

  p {
    margin-top: 16px;
    margin-bottom: 40px;
  }

  .InputsSection {
  }
}

.SectionSeparatorTop {
  padding-top: $SectionSeparatorWeb;
}
.SectionSeparatorTop2 {
  padding-top: $SectionSeparatorWeb2;
}
.SectionSeparatorTop3 {
  padding-top: $SectionSeparatorWeb3;
}

.SectionSeparator {
  padding-bottom: $SectionSeparatorWeb;
}
.SectionSeparator2 {
  padding-bottom: $SectionSeparatorWeb2;
}
.SectionSeparator3 {
  padding-bottom: $SectionSeparatorWeb3;
}

@media screen and (max-width: 660px) {
  .SectionSeparator {
    padding-bottom: $SectionSeparatorMobile;
  }
  .SectionSeparator2 {
    padding-bottom: $SectionSeparatorMobile2;
  }
  .SectionSeparator3 {
    padding-bottom: $SectionSeparatorMobile3;
  }
}

.WhiteSection {
  color: $black;
  background: $white;

  & * {
    color: $black;
  }

  & .Menu li a:hover {
    color: $black;
  }
}

.BlackSection {
  background: $black;
  color: $white;

  & * {
    color: $white;
  }

  & .Menu li a:hover {
    color: $white;
  }
}

.WhiteSection,
.BlackSection {
  // transition: $transition;
  transition: $fastTransition;
}

.Count {
  font: $title1;
}
