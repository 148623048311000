@font-face {
  font-family: "M";
  src: local("Raleway-Bold"),
    url(./fonts/Raleway/Raleway-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "B";
  src: local("Raleway-Black"),
    url(./fonts/Raleway/Raleway-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "R";
  src: local("Raleway-Regular"),
    url(./fonts/Raleway/Raleway-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Audiowide";
  src: local("Audiowide-Regular"),
    url(./fonts/Audiowide-Regular.ttf) format("truetype");
}

// @font-face {
//   font-family: "M";
//   src: local("Muli-Bold"), url(./fonts/Muli/Muli-Bold.ttf) format("truetype");
// }

// @font-face {
//   font-family: "B";
//   src: local("Muli-Black"), url(./fonts/Muli/Muli-Black.ttf) format("truetype");
// }

// @font-face {
//   font-family: "R";
//   src: local("Muli-Regular"),
//     url(./fonts/Muli/Muli-Regular.ttf) format("truetype");
// }

// Font type
$logo: 24px "Audiowide", sans-serif;
$littleText: 12px "R", sans-serif;
$text: 14px "R", sans-serif;
$biggerText: 16px "R", sans-serif;
$biggestText: 18px "R", sans-serif;
$heroText: 52px "Audiowide", sans-serif;
$heading: 24px "B", sans-serif;
$subHeading: 18px "M", sans-serif;
$button: 14px "R", sans-serif;
$link: 14px "R", sans-serif;
// $title1: 36px "B", sans-serif;
$title1: 36px "Audiowide", sans-serif;
$title2: 24px "Audiowide", sans-serif;
$title3: 18px "Audiowide", sans-serif;
